<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/whatsapp')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">
            
                    <div class="">
                        <div class="">
                            <span class="font-semibold text-base">Tambah Nomor Whatsapp</span>
                        </div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">

                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="postData()" class="w-full" >
                            <div class="grid grid-cols-1 gap-4 mt-4">
                                <ValidationProvider rules="required|numeric" v-slot="{ errors }" name="nama" vid="nama" class="grid grid-cols-12 gap-4 items-center">
                                <label for="" class="block mb-2 col-span-1">Number</label>  
                                <div class="col-span-11 relative">
                                    <div class="absolute top-0 left-0">
                                        <div class="px-2 py-2 rounded-l" style="padding-top:0.6rem">
                                         +62

                                        </div>
                                    </div>
                                <input
                                    v-model="addData.number"
                                    type="text"
                                    :class="`block border border-gray-200 w-full pl-8  pr-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                    name="number"
                                    ref="number"
                                    id="number"
                                    />
                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                </div>
                                </ValidationProvider>
                            </div>
                            <div class="grid grid-cols-1 gap-4 mt-4">
                                <ValidationProvider v-slot="{ errors }" name="Group" vid="group" class="grid grid-cols-12 gap-4 items-center">
                                
                                 <label for="" class="block mb-2 mt-2">Group Sensor</label> 
                                  <div class="col-span-11">
                                    <select ref="group_id" name="group_id" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-model="addData.group_id">
                                    <option :value="item.id" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-for="item in groups" :key="item.id">{{item.name}}</option>
                                
                            
                                    </select>
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                                </div>

                                </ValidationProvider>
                            </div>

                              <div class="grid grid-cols-1 gap-4 mt-4">
                                <ValidationProvider v-slot="{ errors }" name="type" vid="type" class="grid grid-cols-12 gap-4 items-center">
                                
                                 <label for="" class="block mb-2 mt-2">Type</label> 
                                  <div class="col-span-11">
                                    <select ref="type_message" name="type_message" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" v-model="addData.type_message">
                                    <option value="1" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" >Semua Notifikasi</option>
                                    <option value="2" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" >Notifikasi Sensor</option>
                                    <option value="3" class="block border border-gray-200 w-full p-2 px-4 rounded text-xs" >Notifikasi Peringatan</option>
                                
                            
                                    </select>
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                                </div>

                                </ValidationProvider>
                            </div>
                            
                            
                            <div class="float-right">
                            <button
                                @click="$router.push('/whatsapp')"
                                :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Batal</button>

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>

                            
                        </form>
                        </ValidationObserver>
                       </div>
                        </div>
                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows:100,
        perPage:10,
        limit:5,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        groups:[],
        addData:{
            number:'',
            group_id:'',
            type_message:1,

           
        },
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
       
        async postData(){
           

            this.axios.put('v2/whatsapp/' + this.$route.params.id,this.addData,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/whatsapp')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },

        async getData() {
            await this.axios.get('v2/whatsapp/' + this.$route.params.id,this.config)
            .then(async (ress) => {
                
                this.addData = ress.data
                this.addData.group_id = this.addData.group_id == null ? '' : this.addData.group_id
            })
        },
    
    },
    computed:{

    },
    async created() {
        this.getGroup()

        await this.getData()

        
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>